import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getDate, isEmptyStr } from '@/utils'
import Homelayout from '@/views/home'

import myservice from './modules/my-service'
import system from './modules/system'
import journal from './modules/journal'
import ihrm from './modules/ihrm'
import customermanagement from './modules/customer-management'
import salesorder from './modules/sales-order'
import personalperformance from './modules/personal-performance'
import customerpickup from './modules/customer-pick-up'
import storemanagement from './modules/store-management'
import messagemanagement from './modules/message-management'
import processSignOff from './modules/processSignOff'
import administrativemanagement from './modules/administrative-management'
import distributor from './modules/distributor'
import financialmanagement from './modules/financial-management'
import managementdecision from './modules/management-decision'
import newdistributor from './modules/new-distributor'
import purchaseorder from './modules/purchase-order'
import ReturnPage from './modules/return-page' // 返回页面
import { WXwhiteList } from '@/utils/publicField'

// import { getUserInfo } from '@/api/user'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:isReturn?',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    props: true
  },
  {
    path: '/new-details-notice-system/:id/:type?',
    name: 'newDetailsNoticeSystem',
    component: () => import('@/views/home/components/home-page/details-notice-system.vue'),
    meta: { title: '集团文章详情' },
    props: true
  },
  {
    path: '/group-announcement/:id/:type?',
    name: 'groupAnnouncement',
    component: () => import('@/views/group-announcement/group-announcement.vue'),
    props: true
  },
  {
    path: '/induction',
    name: 'induction',
    component: () => import('@/views/induction/index.vue'),
    meta: { title: '入职登记' }
  },
  {
    path: '/resetpsw',
    name: 'resetpsw',
    component: () => import('@/views/resetpsw/index.vue'),
    meta: { title: '重置密码' }
  },
  {
    path: '/product-list',
    name: 'product-list',
    component: () => import('@/views/induction/product-list.vue'),
    meta: { title: '产品', name: 'productList', keepAlive: true, scrollTop: 0 }
  },
  {
    path: '/product-list/details-order/:id',
    name: 'home-details-order',
    component: () => import('@/views/induction/product-list/details-order.vue'),
    meta: { title: '产品详情' },
    props: true
  },
  {
    path: '/ScanCodePage',
    name: 'ScanCodePage',
    component: () => import('@/views/scanCodePage'),
    meta: { title: '扫码' },
    props: true
  },
  {
    path: `/GlobalSettings/${getDate(new Date(), 1, true)}/12581`,
    name: 'GlobalSettings',
    component: () => import('@/views/GlobalSettings'),
    meta: { title: '全局设置' },
    props: true
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/404/NotFound.vue'),
    meta: { title: '404' },
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/company-home'),
    meta: { title: '首页' },
    props: true
  },
  {
    path: '/company-homepage',
    name: 'company-homepage',
    component: () => import('@/views/company-home/company-homepage.vue'),
    meta: { title: '首页2', name: 'companyHomepage', keepAlive: true, scrollTop: 0 },
    props: true
  },
  {
    path: '/login-subject-test/:id',
    name: 'login-subject-test',
    component: () => import('@/views/message-management/components/knowledge-question-bank/login-subject-test/login-subject-test.vue'),
    meta: { title: '试卷' },
    props: true
  },
  {
    path: '/',
    component: Homelayout,
    redirect: '/homepage',
    children: [
      {
        path: 'homepage',
        name: 'homepage',
        component: () => import('@/views/home/home-page.vue'),
        meta: { title: '首页', name: 'homePage', keepAlive: true, long: true, scrollTop: 0, scrollLeft: 0 }
      },
      {
        path: 'homework',
        name: 'homework',
        component: () => import('@/views/home/home-work.vue'),
        meta: { title: '工作台', name: 'homeWork', keepAlive: true, long: true, scrollTop: 0 }
      }
    ]
  },
  ...myservice,
  ...system,
  ...journal,
  ...ihrm,
  ...customermanagement,
  ...salesorder,
  ...personalperformance,
  ...customerpickup,
  ...storemanagement,
  ...messagemanagement,
  ...processSignOff,
  ...administrativemanagement,
  ...distributor,
  ...financialmanagement,
  ...managementdecision,
  ...newdistributor,
  ...purchaseorder,
  ...ReturnPage
]

// 解决跳转到404界面时重定向
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const whiteList = ['login', 'resetpsw', 'induction', 'product-list', 'home-details-order', 'login-subject-test', 'groupAnnouncement', 'newDetailsNoticeSystem'] // 白名单
router.beforeEach((to, from, next) => {
  // 判断是否为移动端
  const token = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? localStorage.getItem('token') : getToken()
  if (to.meta) store.dispatch('user/getTitleHome', to.meta.title)
  store.dispatch('user/aSetfromPath', from.name)
  store.dispatch('user/aSettoPageName', to.name)
  if (token && !isEmptyStr(token)) {
    if (!whiteList.includes(to.name) || WXwhiteList.includes(to.name)) {
      next()
    } else {
      next('/')
    }
  } else {
    if (whiteList.includes(to.name) || WXwhiteList.includes(to.name)) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

export default router
