import { userLogin } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import _stort from '../index'
import { parseFn } from '@/utils'
const privateUnitsArr = ['提']
const state = {
  privateUnits: [...privateUnitsArr], // 大单位禁用
  token: getToken() || '',
  fromPath: '',
  userInfo: {},
  passwordStatus: false,
  powerStatus: '加载中...', // 0 加载中， 1 权限控制， 2 系统有误，请刷新
  statusIndex: false,
  toPageName: '',
  unreadNum: {
    flowRemindCount: null,
    notPayOrder: null,
    listNum: null,
    signing: null,
    tosign: null
  }
}
const mutations = {
  setfromPath (state, data) {
    state.fromPath = data
  },
  settoPageName (state, data) {
    state.toPageName = data
  },
  setToken (state, data) {
    // 将vuex数据持久化
    setToken(data)// 将token存储到cookies中
    sessionStorage.setItem('token', data)
    localStorage.setItem('token', data)
    state.token = data
  },
  removeToken (state) {
    removeToken() // 清理cookie中的token
    _stort.state.user.token = ''
    _stort.state.user.returnCharacteristic = {
      url: null,
      status: false
    }
    _stort.state.user.statusIndex = false
    _stort.state.user.classId = ''
    _stort.state.user.userInfo = {}
    _stort.state.order = {}
    _stort.state.temporaryData = {}
    _stort.state.faceData = {}
    sessionStorage.clear()
    localStorage.removeItem('vuex')
    localStorage.removeItem('token')
    localStorage.removeItem('lock')
  },
  setTitleHome (state, data) {
    state.titleHome = data
  },
  setStatusOrder (state, data) { // 添加订单状态
    if (data.attrname === 'order') state.statusOrder = data.data
    if (data.attrname === 'dropdown') state.dropdown = data.data
    if (data.attrname === 'dropdown2') state.dropdown2 = data.data
  },
  removeStatusOrder (state) { // 清除我的订单状态
    state.statusOrder = ''
    state.dropdown = ''
    state.dropdown2 = ''
  },
  setStatusIndex (state, data) { // 导航栏状态
    state.statusIndex = data
  },
  setClassId (state, data) { // 是否经销商
    state.classId = data
  },
  setUserInfo (state, data) { // 存储个人信息
    state.userInfo = data
    state.privateUnits = state.userInfo.memuCodeList.includes('group:lock:unit') ? [] : parseFn(privateUnitsArr)
  },
  setUnreadNum (state, data) { // 存储 消息列表未读数量 与 待签核数量
    state.unreadNum[data.name] = data[data.name]
  },
  setPasswordStatus (state, data) { // 储存 过期密码 时 弹窗的状态
    state.passwordStatus = data
  },
  setPowerStatus (state, data) { // 储存 页面的状态
    state.powerStatus = data == 0 ? '加载中...' : (data == '1' ? '没有更多的数据' : '请刷新界面...')
  }
}
const actions = {
  aSetfromPath (store, data) {
    store.commit('setfromPath', data)
  },
  aSettoPageName (store, data) {
    store.commit('settoPageName', data)
  },
  async login (store, data) {
    const res = await userLogin(data)
    store.commit('setToken', res.data)
  },
  setUserInfo (store, data) {
    store.commit('setUserInfo', data)
  },
  cleanupData (store) {
    store.commit('removeToken')
  },
  getTitleHome (store, data) {
    store.commit('setTitleHome', data)
  },
  getStatusIndex (store, data) { // 导航栏状态状态
    store.commit('setStatusIndex', data)
  },
  cleanupStatusOrder (store) { // 清除我的订单状态
    store.commit('removeStatusOrder')
  },
  getClassId (store, data) { // 是否经销商
    store.commit('setClassId', data)
  },
  setUnreadNum (store, data) {
    store.commit('setUnreadNum', data)
  },
  setPasswordStatus (store, data) {
    store.commit('setPasswordStatus', data)
  },
  setPowerStatus (store, data) {
    store.commit('setPowerStatus', data)
  },
  setToken (store, data) {
    store.commit('setToken', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
